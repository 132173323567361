import React, { useRef, useEffect } from "react";
import './Application-lifecycle-management.css';  // Import CSS styles
import { Link } from 'react-router-dom';
import image1 from './image2.jpg';
import image2 from './image1.png';
// import image2 from '../Lease_Accounting/Lease_Accounting3rd.jpg';

const Esg_Reporting = () => {
    const headingRef = useRef(null);
    const descriptionRef = useRef(null);
    const textContentRef = useRef(null);
    const imageContentRef = useRef(null);
     // New ref for content-section-2
    const handleButtonClick = () => {
      window.location.href = "/contactus"; // External URL
    };
    
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("in-view");
            } else {
              entry.target.classList.remove("in-view");
            }
          });
        },
        {
          threshold: 0.1, // Adjust the threshold to ensure animations trigger earlier
        }
      );

      // Observe all relevant elements
      const refs = [headingRef, descriptionRef,textContentRef,imageContentRef]; // Added textContentRef2
      refs.forEach((ref) => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      });

      // Cleanup observer on component unmount
      return () => {
        refs.forEach((ref) => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        });
      };
    }, []);

    return (
      <div>
        <div className="background-c-services">
          <div className="contents" ref={headingRef}>
            <div className="titles">Application Lifecycle Management (ALM)</div>
            <div className="descriptions" ref={descriptionRef}>
                Amplify your company’s competitiveness with our Application Lifecycle Management (ALM).
                With today’s fast-paced technology, our experts can help you execute new technology with ease
                and proactively mitigate IT security risks without breaking the bank.  
            </div>    
          </div>
        </div>
        <div class="bottom-design"></div>
        <div className="content-section">
          <div className="text-content" ref={textContentRef}>
            <h2 className="management-h2">Empower Your Core Business with JS DYNAMICS's Comprehensive Application Lifecycle Management</h2>
            <p>We remove the burden of infrastructure and application management from our clients so they can re-allocate the use of their resources towards their core business. Our all-in solution for technical & functional development and maintenance helps ensure that clients’ business and management requirements are constantly in focus.</p>
            <br/>
            <p>We offer a holistic solution, Application Lifecycle Management (ALM)  that provides expert advise and support for the full lifecycle of CPM & ERP applications. The primary purpose of Managed Services is to ensure that our customers get the most out of their technology investments by handing over the on-going maintenance and support of their system and applications to us.</p>
            <br />
            <p>Most customers work with 3rd party hosting providers and <span className="para-in-black-words">JS DYNAMICS</span> can easily plug in the maintenance and support model to integrate with the current service set-up. On the functional side, we handle everything from daily administration tasks, security provisioning, development, incident- service request and change management through to data loading, reconciliation and controlling of tasks. We deliver this service for OneStream, but also for Dynamics 365.</p>
            <br />  
            <p>All deliverables within the Managed Services are completed following standard Response Times, supported by a manned Service Desk as well as ticketing systems such as Jira.The ALM is described in more detail below:</p>
         
          </div>
          <div className="image-content" ref={imageContentRef}>
            <img src={image2} alt="Lease Accounting" />
           </div>
           
          </div>
        

        <div className="custom-cards-container">
      <div className="custom-card">
        <h3>Business Process Analysis</h3>
        <ul>
          <li>Pre-Project Analysis</li>
          <li>Business Process Mapping</li>
          <li>Design and Blueprint</li>
        </ul>
      </div>

      <div className="custom-card">
        <h3>Solutions Qualification</h3>
        <ul>
          <li>Solutions Evaluation</li>
          <li>Best Fit Selection</li>
        </ul>
      </div>

      <div className="custom-card">
        <h3>Support & Maintenance</h3>
        <ul>
          <li>Infrastructure and Application Support</li>
          <li>Maintenance and Administration</li>
        </ul>
      </div>

      <div className="custom-card">
        <h3>Implementation & Transition</h3>
        <ul>
          <li>Experienced Consultants</li>
          <li>Cost-efficient Model Using Local and Offshore Resources</li>
        </ul>
      </div>
    </div>
    <div className="cta-container">
    <div className="cta-content">
      <h2>Invest in our experienced and certified professionals now.</h2>
      <p>Talk to our experts.</p>
      <button class='button-contact-us' onClick={handleButtonClick}>
        <svg
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
            fill="currentColor"
          ></path>
        </svg>
        <span>Contact Us</span>
      </button>
    </div>
  </div>
      </div>
      
    );
};

export default Esg_Reporting;
