import React, { useRef, useEffect } from "react";
import './All-Services.css';  // Import CSS styles
import { Link } from 'react-router-dom';
import image1 from './image1.jpg';
import image2 from './image2.jpg';
import image3 from './image3.png';
import image4 from './image4.png';
import image5 from './image5.jpg';
// import image2 from '../Lease_Accounting/Lease_Accounting3rd.jpg';

const Esg_Reporting = () => {
    const headingRef = useRef(null);
    const descriptionRef = useRef(null);
    const textContentRef = useRef(null);
    const imageContentRef = useRef(null);
    const imageContentRef2 = useRef(null);
    const imageContentRef3 = useRef(null);
    const imageContentRef4 = useRef(null);
    const textContentRef2 = useRef(null); // New ref for content-section-2
    const textContentRef3 = useRef(null); // New ref for content-section-2
    const textContentRef4 = useRef(null); // New ref for content-section-2
    const handleButtonClick = () => {
      window.location.href = "/contactus"; // External URL
    };
      const handleButtonClick_Advisory = () => {
      window.location.href = "/Services/Advisory-Services"; // External URL
    };
    const handleButtonClick_Implementation = () => {
      window.location.href = "/Services/Implementation"; // External URL
    };
    const handleButtonClick_management = () => {
      window.location.href = "/Services/Application-Lifecycle-Management"; // External URL
    };
    
    const handleButtonClick_training = () => {
      window.location.href = "/Services/Training"; // External URL
    };
    
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("in-view");
            } else {
              entry.target.classList.remove("in-view");
            }
          });
        },
        {
          threshold: 0.1, // Adjust the threshold to ensure animations trigger earlier
        }
      );

      // Observe all relevant elements
      const refs = [headingRef, descriptionRef, textContentRef, imageContentRef, textContentRef2,textContentRef3,imageContentRef2,imageContentRef3,imageContentRef4,textContentRef4]; // Added textContentRef2
      refs.forEach((ref) => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      });

      // Cleanup observer on component unmount
      return () => {
        refs.forEach((ref) => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        });
      };
    }, []);

    return (
      <div>
        {/* <div className="background-c background-c-services">
          <div className="contents" ref={headingRef}>
            <div className="titles">Services</div>
            <div className="descriptions" ref={descriptionRef}>
            At <span className="shine3">JS DYNAMICS</span>, we help you optimize your transactional processing and enhance your management decision-making. Our goal is to provide you with accurate and timely business information that adds value and fuels growth in the market. With our expertise and innovative solutions, you can confidently navigate the complexities of today's business landscape.
           
            </div>
          </div>
        </div> */}

        <div className="background-c-services">
        <div className="contents" ref={headingRef}>
          <div className="titles">Our Services</div>
          <div className="descriptions" ref={descriptionRef}>
          At <span className="shine3">JS DYNAMICS</span>, we help you optimize your transactional processing and enhance your management decision-making. Our goal is to provide you with accurate and timely business information that adds value and fuels growth in the market. With our expertise and innovative solutions, you can confidently navigate the complexities of today's business landscape.
          </div>
        </div>
      </div>
      <div class="bottom-design"></div>
        <div className="content-section">
          <div className="text-content" ref={textContentRef}>
            <h2>ADVISORY SERVICES</h2>
            <p className="para-all-services">General Advisory, Roadmap Planning,and Process Reviews & Optimization Paving the way for successful finance transformations. Discover how to transform your operations and adapt to the ever-changing needs of your organization with measurable and achievable business solutions for your short and long-term goals.</p>
            <button class='btn-learn-more-services' onClick={handleButtonClick_Advisory}>
            <span class="span-mother">
              <span>L</span>
              <span>E</span>
              <span>A</span>
              <span>R</span>
              <span>N</span> 
              <span id="space"></span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>E</span>
            </span>
            <span class="span-mother2">
              <span>L</span>
              <span>E</span>
              <span>A</span>
              <span>R</span>
              <span>N</span>
              <span> </span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>E</span>
            </span>
          </button>
          </div>
          <div className="image-content" ref={imageContentRef}>
            <img src={image2} alt="Lease Accounting" />
           </div>
           
          </div>

      <div className="service-section">
      <div className="service-image-container" ref={imageContentRef2}>
        <img src={image3} alt="Service" className="service-image" />
      </div>
      <div className="service-text-container" ref={textContentRef2}>
        <h2>SERVICE IMPLEMENTATION</h2>
        <p>
          Design and deployment of market-leading CPM and ERP. Building solutions anchored on best-practice project management, financial processes, and infrastructure.
        </p>
          <button class='btn-learn-more-services btn-service-text' onClick={handleButtonClick_Implementation}>
          <span class="span-mother">
            <span>L</span>
            <span>E</span>
            <span>A</span>
            <span>R</span>
            <span>N</span> 
            <span id="space"></span>
            <span>M</span>
            <span>O</span>
            <span>R</span>
            <span>E</span>
          </span>
          <span class="span-mother2">
            <span>L</span>
            <span>E</span>
            <span>A</span>
            <span>R</span>
            <span>N</span>
            <span> </span>
            <span>M</span>
            <span>O</span>
            <span>R</span>
            <span>E</span>
          </span>
        </button>
      </div>
    </div>
    
    <div className="content-section">
          <div className="text-content" ref={textContentRef3}>
            <h2>APPLICATION LIFECYCLE MANAGEMENT (ALM)</h2>
            <p className="black-words">On-going support and maintenance of enterprise applications </p>
            <p>Increase efficiency, and refocus priorities on expanding your business’s core competencies and resources by handing over day-to-day platform management to our experts </p>
            <button class='btn-learn-more-services' onClick={handleButtonClick_management}>
            <span class="span-mother">
              <span>L</span>
              <span>E</span>
              <span>A</span>
              <span>R</span>
              <span>N</span> 
              <span id="space"></span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>E</span>
            </span>
            <span class="span-mother2">
              <span>L</span>
              <span>E</span>
              <span>A</span>
              <span>R</span>
              <span>N</span>
              <span> </span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>E</span>
            </span>
          </button>
          </div>
          <div className="image-content" ref={imageContentRef3}>
            <img src={image4} alt="Lease Accounting" />
           </div>
          </div>
    
    
    <div className="service-section">
      <div className="service-image-container" ref={imageContentRef4}>
        <img src={image5} alt="Service" className="service-image" />
      </div>
      <div className="service-text-container" ref={textContentRef4}>
        <h2>TRAINING</h2>
        <p className="black-words">Enabling in-house expertise and solution literacy</p>
        <p><span className="para-in-black-words">JS DYNAMICS</span> offers a wide range of flexible trainings from beginners to advanced level for OneStream Software delivered by highly professional and certified practitioners. Our trainings are designed to help you achieve finance and IT transformation goals.
        </p>
          <button class='btn-learn-more-services btn-service-text' onClick={handleButtonClick_training}>
          <span class="span-mother">
            <span>L</span>
            <span>E</span>
            <span>A</span>
            <span>R</span>
            <span>N</span> 
            <span id="space"></span>
            <span>M</span>
            <span>O</span>
            <span>R</span>
            <span>E</span>
          </span>
          <span class="span-mother2">
            <span>L</span>
            <span>E</span>
            <span>A</span>
            <span>R</span>
            <span>N</span>
            <span> </span>
            <span>M</span>
            <span>O</span>
            <span>R</span>
            <span>E</span>
          </span>
        </button>
      </div>
    </div>
    <div className="cta-container">
    <div className="cta-content">
      <h2>Invest in our experienced and certified professionals now.</h2>
      <p>Talk to our experts.</p>
      <button class='button-contact-us' onClick={handleButtonClick}>
        <svg
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
            fill="currentColor"
          ></path>
        </svg>
        <span>Contact Us</span>
      </button>
    </div>
  </div>
      </div>
      
    );
};

export default Esg_Reporting;
