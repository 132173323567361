import React from 'react';
import './plan_a_demo.css';
import backgroundImage from '../../know-more-bckgrnd.webp'; // Replace with actual image path
import teamMeeting from './team_meeting.jpg';
function planDemo() {
  const handleButtonClick = () => {
    window.location.href = "/contactus"; // External URL
  };
  return (
    <div>
    <div className="container-Lease-Accounting-Down">
      <div className="Lease-Accounting-Down-Container-left-section">
        <h2>Start Now</h2>
        <ul>
          <li>Work with the creators of the solution</li>
          <li>Don't reinvent the wheel</li>
          <li>Finance expertise enables us to configure to your business requirements</li>
          <li>The project can be fully executed remotely</li>
        </ul>
        {/* <button className="demo-button">PLAN A DEMO</button> */}
        <button class='button-rq-demo' onClick={handleButtonClick}>
        <span>PLAN A DEMO</span>
        <div class="top"></div>
         <div class="left"></div>
        <div class="bottom"></div>
        <div class="right"></div>
      </button>
      </div>
      <div className="right-section">
        <img src={teamMeeting} alt="Team meeting" />
      </div>
    </div>
    </div>
  );
}

export default planDemo;
