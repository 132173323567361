import React from 'react';
import './Privacy-Statement.css'; // Import the CSS file for styling

const PrivacyStatement = () => {
  return (
    <div className="privacy-statement-container">
      
      <section className="imageSection">
        <div className="textOverlay">
          <h1 className='Privacy-Heading'>Privacy Statement </h1>
          <p className='Privacy-para'>
           Check Privacy Statement of JS DYNAMICS
          </p>
        </div>
        <div className="wave-containers">
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </section>

      <section className="privacy-content">
        <p className="privacy-text">
        At JS Dynamics, we are committed to protecting the privacy and security of our website visitors and users. This Privacy Statement outlines how we collect, use, disclose, and protect your personal information when you visit our website. By using our website, you consent to the practices described in this statement.
        </p>
        <h2>Information We Collect:</h2>
        <p className="privacy-text">
        We may collect personal information such as your name, email address, contact details, and any other information you voluntarily provide to us when you interact with our website, subscribe to our newsletter, or engage in any other activities on our site.
        </p>
        <h2>Use of Information:</h2>
        <p className="privacy-text">
        We use the collected information to provide you with a personalized experience on our website, to respond to your inquiries, to send you relevant information, updates, and newsletters, and to improve our website's content and functionality. We may also use your information for marketing and promotional purposes with your consent.
        </p>
        <h2>Cookies and Tracking Technologies:</h2>
        <p className="privacy-text">
        We use cookies and similar tracking technologies to enhance your browsing experience, analyze website traffic, and gather information about how our website is used. You can choose to accept or decline cookies through your browser settings. However, disabling cookies may affect your ability to access certain features of our website.
        </p>
        <h2>Information We Collect:</h2>
        <p className="privacy-text">
        We may collect personal information such as your name, email address, contact details, and any other information you voluntarily provide to us when you interact with our website, subscribe to our newsletter, or engage in any other activities on our site.
        </p>
        <h2>Third-Party Links:</h2>
        <p className="privacy-text">
        Our website may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy practices or content of these websites. We encourage you to review the privacy policies of any third-party websites you visit.
        </p>
        <h2>Data Security:</h2>
        <p className="privacy-text">
        We implement reasonable security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
        </p>
        <h2>Children's Privacy:</h2>
        <p className="privacy-text">
        Our website is not intended for use by individuals under the age of 16. We do not knowingly collect personal information from children. If we become aware that we have inadvertently collected personal information from a child under 16 without parental consent, we will take steps to delete that information.
        </p>
        <h2>Right to Deletion:</h2>
        <p className="privacy-text">
        You have the right to request that we delete any of your personal information that we have collected from you and retained, subject to certain exceptions. For example, we may deny your deletion request if retaining the information is necessary for us to complete the transaction or service that you requested, detect security incidents, or enable internal uses that are reasonably aligned with your expectations.
        </p>
        <h2>Changes to the Privacy Statement:</h2>
        <p className="privacy-text">
        We may update our Privacy Statement from time to time. Any changes will be posted on this page with the updated date. We encourage you to review this statement periodically to stay informed about how we collect, use, and protect your personal information.
        </p>
        <h2>Contact Us:</h2>
        <p className="privacy-text">
        If you have any questions, concerns, or requests regarding our Privacy Statement or the handling of your personal information, please contact us at HR@jnsdynamics.com
        <br></br>
        This Privacy Statement was last updated on October 18, 2024.
        </p>
      </section>
    </div>
  );
};

export default PrivacyStatement;
