import React, { useRef, useEffect } from "react";
import './Esg_Reporting.css';  // Import CSS styles
import { Link } from 'react-router-dom';
import image1 from '../Lease_Accounting/image1.jpeg';
import image2 from '../Lease_Accounting/Lease_Accounting3rd.jpg';

const Esg_Reporting = () => {
    const headingRef = useRef(null);
    const descriptionRef = useRef(null);
    const textContentRef = useRef(null);
    const imageContentRef = useRef(null);
    const textContentRef2 = useRef(null); // New ref for content-section-2
    
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("in-view");
            } else {
              entry.target.classList.remove("in-view");
            }
          });
        },
        {
          threshold: 0.1, // Adjust the threshold to ensure animations trigger earlier
        }
      );

      // Observe all relevant elements
      const refs = [headingRef, descriptionRef, textContentRef, imageContentRef, textContentRef2]; // Added textContentRef2
      refs.forEach((ref) => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      });

      // Cleanup observer on component unmount
      return () => {
        refs.forEach((ref) => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        });
      };
    }, []);

    return (
      <div>
        <div className="background-cS">
          <div className="contents" ref={headingRef}>
            <div className="titles">Improve your ESG Rating with ESG & Sustainability
            Reporting for OneStream</div>
            <div className="descriptions" ref={descriptionRef}>
              {/* Add your content here */}
            </div>
          </div>
        </div>
        <div className="content-section">
          <div className="text-content" ref={textContentRef}>
            <h2>Improve your ESG score and assure the success of ESG initiatives.</h2>
            <p>
            By adding ESG Reporting to your OneStream platform you can take control over your ESG Score and have the ability to set your own compliance timeline for reporting requirements as they evolve globally. Get a holistic view on how ESG Initiatives and financial performance come together.
            Download the ESG and Sustainability Reporting for OneStream Datasheet to learn more.</p>
          </div>
          <div className="image-content" ref={imageContentRef}>
            <img src={image1} alt="Lease Accounting" />
        
          </div>
          </div>
          
        {/* <div className="lease-container">
          <div className="lease-image">
            <img src={image2} alt="Lease Accounting" />
            <div className="lease-description">
              <h2>Key Functionalities</h2>
              <ul>
                <li>One central register for all lease contracts</li>
                <li>Automated journal generation supporting both IFRS 16 and ASC 842 calculations</li>
                <li>Easily calculate the lease liability and related impacts of your lease portfolio</li>
                <li>Instant overview of all leases</li>
                <li>Out of the box reports and disclosures to report under the new standards</li>
              </ul>
            </div>
          </div>
        </div> */}

        {/* <div className="content-section-2">
          <div className="text-content-2" ref={textContentRef2}>
            <h2>A JS DYNAMICS Solutions Original</h2>
            <p>
              The Lease Accounting solution was built by <span className="para-in-black-words">JS Dynamics</span> Solutions because we foresaw difficulties with the new IFRS 16 accounting standard. Shortly thereafter our solution was adopted by a major global OneStream client with subsequent other clients who also dealt with IFRS 16 calculation difficulties. Our solution was able to help these customers deal with a multitude of different leases and calculation variables. Since our first project the solution had continued to mature and now is able to address requirements for companies across all branches and industries.
            </p>
          </div>
        </div> */}

        {/* Timeline Section */}
       
      </div>
      
    );
};

export default Esg_Reporting;
