import React, { useEffect } from 'react'; 
import Dashboard from './component/Dashboard/Dashboard';
import Header from './component/Dashboard/Components/Header/Header';
import Slider from './component/Dashboard/Components/Header/Slider';
import Login from './component/Login/Login';
import Register from './component/Register/Register';
import Mains from './component/Dashboard/Components/Mains/Mains';
import Cards from './component/Dashboard/Components/Cards_Down/Cards_Down';
import Footer from './component/Dashboard/Components/Footer/Footer';
import AboutUs from './component/Dashboard/Components/About_Us/About_Us';
import ContactUs from './component/Dashboard/Components/Contact_Us/Contact_Us';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'; // Use BrowserRouter
import OurCompany from './component/Dashboard/Components/About_Us/About_Us_Our_Company';
import AllSolutions from './component/Dashboard/Components/Solutions/All_Solutions';
import LeaseAccounting from './component/Dashboard/Components/Solutions/Lease_Accounting/Lease_Accounting';
import PlanDemo from './component/Dashboard/Components/Solutions/Lease_Accounting/plan_a_demo/plan_a_demo';
import EsgReporting from './component/Dashboard/Components/Solutions/Esg_Reporting/Esg_Reporting';
import PillarTwo from './component/Dashboard/Components/Solutions/beps_pillar_two_global_tax_management/beps_pillar_two_global_tax_management';
import ProfitablityAndCostAnalysis from './component/Dashboard/Components/Solutions/profitability_and_cost_analysis/profitability_and_cost_analysis';
import Services from './component/Dashboard/Components/OneStream-Services/All-Services';
import AdvisoryServices from './component/Dashboard/Components/OneStream-Services/Advisory-Services/Advisory_Services';
import Implementation from './component/Dashboard/Components/OneStream-Services/Implementation/Implementation';
import Management from './component/Dashboard/Components/OneStream-Services/Application-lifecycle-management/Application-lifecycle-management';
import Training from './component/Dashboard/Components/OneStream-Services/Training/Training';
import Optimization from './component/Dashboard/Components/OneStream-Services/Optimization/Optimization';
import WhyOneStream from './component/Dashboard/Components/Solutions/why_onestream/why_onestream';
import Privacy from './component/Dashboard/Components/Privacy-Statement/Privacy-Statement';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <div>
        <Dashboard />
        <Slider />
        <Mains />
        <Cards />
        <Footer />
      </div>
    ),
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/dashboard',
    element: (
      <div>
        <Dashboard />
        <Slider />
        <Mains />
        <Cards />
        <Footer />
      </div>
    ),
  },
  {
    path: '/about-us',
    element: (
      <div>
        <Header />
        <OurCompany />
        <AboutUs />
        <Footer />
      </div>
    ),
  },
  {
    path: '/contactus',
    element: (
      <div>
        <Header />
        <ContactUs />
        <Footer />
      </div>
    ),
  },
  {
    path: '/about-us/our-company',
    element: (
      <div>
        <Header />
        <OurCompany />
        <AboutUs />
        <Footer />
      </div>
    ),
  },
  {
    path: '/Solutions',
    element: (
      <div>
        <Header />
        <AllSolutions />
        <WhyOneStream />
        <Cards />
        <Footer />
      </div>
    ),
  },
  {
    path: '/Solutions/Lease-Accounting',
    element: (
      <div>
        <Header />
        <LeaseAccounting />
        <PlanDemo />
        <WhyOneStream />
        <Cards />
        <Footer />
      </div>
    ),
  },
  {
    path: '/Solutions/Esg-Reporting',
    element: (
      <div>
        <Header />
        <EsgReporting />
        <PlanDemo />
        <WhyOneStream />
        <Cards />
        <Footer />
      </div>
    ),
  },
  {
    path: '/Solutions/beps_pillar_two_global_tax_management',
    element: (
      <div>
        <Header />
        <PillarTwo />
        <PlanDemo />
        <WhyOneStream />
        <Cards />
        <Footer />
      </div>
    ),
  },
  {
    path: '/Solutions/profitablity-and-cost-analysis',
    element: (
      <div>
        <Header />
        <ProfitablityAndCostAnalysis />
        <PlanDemo />
        <WhyOneStream />
        <Cards />
        <Footer />
      </div>
    ),
  },
  {
    path: '/Services',
    element: (
      <div>
        <Header />
        <Services />
        <WhyOneStream />
        <Cards />
        <Footer />
      </div>
    ),
  },
  {
    path: '/Services/Advisory-Services',
    element: (
      <div>
        <Header />
        <AdvisoryServices />
        <Footer />
      </div>
    ),
  },
  {
    path: '/Services/Implementation',
    element: (
      <div>
        <Header />
        <Implementation />
        <Footer />
      </div>
    ),
  },
  {
    path: '/Services/Optimization',
    element: (
      <div>
        <Header />
        <Optimization />
        <Footer />
      </div>
    ),
  },
  {
    path: '/Services/Application-Lifecycle-Management',
    element: (
      <div>
        <Header />
        <Management />
        <Footer />
      </div>
    ),
  },
  {
    path: '/Services/Training',
    element: (
      <div>
        <Header />
        <Training />
        <Footer />
      </div>
    ),
  },
  {
    path: '/Privacy-Statement',
    element: (
      <div>
        <Header />
        <Privacy/>
      <Footer />
      </div>
    ),
  },
]);

function App() {
  // Prevent automatic trailing slash
  useEffect(() => {
    if (window.location.pathname.endsWith('/')) {
      window.history.replaceState(null, '', window.location.pathname.slice(0, -1));
    }
  }, []);

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
