import React, { useState } from "react";
import "./Footer.css";
import logo from '../../../../LoginAssets/favicon.png';
import { FaLinkedin, FaPaperPlane, FaPlus, FaMinus } from "react-icons/fa";

const Footer = () => {
  const [showSolutions, setShowSolutions] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [showLinks, setShowLinks] = useState(false);

  const toggleSolutions = () => setShowSolutions(!showSolutions);
  const toggleServices = () => setShowServices(!showServices);
  const toggleLinks = () => setShowLinks(!showLinks);

  return (
    <footer>
      <div className="containers">
        <div className="row">
          <div className="col" id="company">
          <div class="shine">JS DYNAMICS</div>
            {/* <img src={logo} alt="Company Logo" className="logo" /> */}
            <h2>OneStream Services. Empowering your digital presence.</h2>
            <div className="social">
              <a href="https://www.linkedin.com/company/js-dynamics-epm/"><FaLinkedin id="LinkedInIcon" /></a>
            </div>
          </div>
         

          <div className="col">
            <h3 onClick={toggleServices}>
              Services {showServices ? <FaMinus /> : <FaPlus className="plusIcon" />}
            </h3>
            <div className={`links ${showServices ? "show" : ""}`}>
              <a href="/Services/Advisory-Services">Advisory Services</a>
              <a href="/Services/Implementation">Implementation</a>
              <a href="/Services/Optimization">Optimization</a>
              <a href="/Services/Application-Lifecycle-Management">Application Lifestyle Management</a>
              <a href="/Services/Training">Training</a>
              <a href="/Services">All Services</a>
            </div>
          </div>
          
          <div className="col">
            <h3 onClick={toggleLinks}>
              About Us {showLinks ? <FaMinus /> : <FaPlus className="plusIcon" />}
            </h3>
            <div className={`links ${showLinks ? "show" : ""}`}>
              <a href="/about-us/our-company">Our Company</a>
              {/* <a href="/about-us">Our History</a>
              <a href="/about-us">Our Partners</a> */}
              <a href="#">Working at JS Dynamics</a>
              <a href="/contactus">Contact Us</a>
            </div>
          </div>
          <div className="col">
            <h3 onClick={toggleSolutions}>
              Solutions {showSolutions ? <FaMinus /> : <FaPlus className="plusIcon" />}
            </h3>
            <div className={`links ${showSolutions ? "show" : ""}`}>
              {/* <a href="/Solutions/Lease-Accounting">Lease Accounting</a>
              <a href="/Solutions/Esg-Reporting">ESG Reporting</a>
              <a href="/Solutions/beps_pillar_two_global_tax_management">BEPS Pillar Two Global Tax</a>
              <a href="/Solutions/profitablity-and-cost-analysis">Profitability and Cost Analysis</a> */}
              <a href="/Solutions">All OneStream Solutions</a>
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="form">
            <form action="">
              <input type="text" placeholder="Email here..." />
              <button type="submit"><FaPaperPlane /></button>
            </form>
          </div>
        </div> */}
         <div className="PrivacyStatement">
            <a href="/Privacy-Statement">Privacy Statement</a>
          </div>
      </div>
      <hr class="styled-hr" />
      <div class="footer-section">
        <div class="footer-content">
          <div class="footer-info">
            Copyright © <span id="get-current-year">2024 By JS DYNAMICS</span>
          </div>
        </div>
        
      </div>

    </footer>
  );
};

export default Footer;
