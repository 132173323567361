import React, { useRef, useEffect } from "react";
import './Optimization.css';  // Import CSS styles
import { Link } from 'react-router-dom';
import image1 from './image1.jpg';
import image2 from './image2.jpg';
import image3 from './image3.jpg';
import image4 from './image3.jpg';
// import image2 from '../Lease_Accounting/Lease_Accounting3rd.jpg';

const Optimization = () => {
    const headingRef = useRef(null);
    const descriptionRef = useRef(null);
     // New ref for content-section-2
    const handleButtonClick = () => {
      window.location.href = "/contactus"; // External URL
    };
    
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("in-view");
            } else {
              entry.target.classList.remove("in-view");
            }
          });
        },
        {
          threshold: 0.1, // Adjust the threshold to ensure animations trigger earlier
        }
      );

      // Observe all relevant elements
      const refs = [headingRef, descriptionRef]; // Added textContentRef2
      refs.forEach((ref) => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      });

      // Cleanup observer on component unmount
      return () => {
        refs.forEach((ref) => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        });
      };
    }, []);

    return (
      <div>
        <div className="background-c-services-optimization">
          <div className="contents" ref={headingRef}>
            <div className="titles">Optimization</div>
            <div className="descriptions" ref={descriptionRef}>
            Optimizing OneStream involves fine-tuning the platform to ensure better performance, efficiency, and usability for your financial operations. Here are key areas of optimization:
              {/* Add your content here */}
            </div>
          </div>
        </div>
        <div class="bottom-design"></div>
        <section className="implementation-methodology-container">
      <div className="implementation-methodology-content">
        <div className="implementation-methodology-text">
          <h2 className="implementation-methodology-heading">How to optimize OneStream CPM performance?</h2>
          <p className="implementation-methodology-paragraph">
          {/* At <span className="para-in-black-words">JS DYNAMICS</span>, we follow a structured and proven approach to implementing OneStream, ensuring seamless integration and optimized performance for your financial processes. */}
          </p>
          {/* <p className="implementation-methodology-paragraph">
          </p> */}
          <ul className="implementation-methodology-list">
            <li><span className="para-in-black-words">Data Load Optimization: </span> Use incremental data loads and clean source data to minimize processing time.</li>
            <li><span className="para-in-black-words">Simplify Calculations: </span> Reduce complexity in business rules, pre-calculate metrics, and optimize cube views for faster calculations.</li>
            <li><span className="para-in-black-words">Streamline Reports & Dashboards:</span> Focus reports on key metrics, use caching, and minimize heavy visuals to improve load times.</li>
            <li><span className="para-in-black-words">Enable Parallel Processing: </span> Run tasks simultaneously and optimize task sequencing to avoid workflow bottlenecks.</li>
            <li><span className="para-in-black-words">Resource Management:</span> Ensure adequate server resources, monitor performance, and perform regular database maintenance</li>
            <li><span className="para-in-black-words">User and Security Management:</span> Limit user access to necessary data and optimize workflows to reduce system load.</li>
            <li><span className="para-in-black-words">Automate Workflows: </span> Schedule tasks during off-peak hours and automate frequent processes.</li>
            <li><span className="para-in-black-words">Keep Systems Updated:</span> Regularly apply software updates and optimize integration with other systems.</li>
          </ul>
        </div>
        <div className="implementation-methodology-image-container">
          <img 
            src={image1}
            alt="Person with data visualization" 
            className="implementation-methodology-image" 
          />
        </div>
      </div>
    </section>
    <div className="card-key-fun">
      <div className="key-fun-image">
        <img 
          src={image4} 
          alt="Card illustration" 
          className="image" 
        />
      </div>
      <div className="key-fun-content">
        <h2 className="key-fun-heading">Best practices and tips</h2>
        <ul className="key-fun-list">
          <li><span className="para-in-black-words">Plan and Design Efficiently:</span> Clearly define business processes and data flow before implementation.</li>
          <li><span className="para-in-black-words">Use Incremental Data Loads:</span> Only load changed or new data to improve performance and reduce data processing time.</li>
          <li><span className="para-in-black-words">Simplify Business Rules:</span> Minimize complex calculations and use pre-calculated metrics where possible to enhance speed.</li>
          <li><span className="para-in-black-words">Leverage Parallel Processing:</span> Enable and configure parallel task processing to handle multiple operations simultaneously.</li>
          <li><span className="para-in-black-words">Optimize Reports and Dashboards:</span> Focus on key data points in reports, avoid overloading dashboards with unnecessary visuals, and use caching for faster access.</li>
          <li><span className="para-in-black-words">Regular Database Maintenance:</span> Reindex, clean up old data, and monitor logs to keep the database running smoothly.</li>
          <li><span className="para-in-black-words">Monitor and Tune Performance:</span> Regularly track system performance and address any slow-running processes or queries.</li>
          <li><span className="para-in-black-words">Automate Recurring Tasks:</span> Set up automation for frequent processes like data loads, consolidations, and reports to save time.</li>
          <li><span className="para-in-black-words">User Access Control:</span> Grant users only the necessary access to data and features to improve security and system efficiency.</li>
          <li><span className="para-in-black-words">Stay Updated:</span> Keep OneStream and related software up-to-date to leverage new features and performance enhancements.</li>
        </ul>
      </div>
    </div>
        <div className="lease-container">
          <div className="lease-image">
            <img src={image2} className="image-advisory-services" />
            <div className="lease-description">
              <h2>OneStream Implementation</h2>
              <ul>
                <li>Improve your business’s strategies and efforts and solve complex financial consolidation & financial reporting tasks with OneStream solutions. With <span className="para-in-black-words">JS DYNAMICS's</span> implementation of CPM solutions, you can leverage on data-driven decisions to achieve organizational success by assessing your current game plan.</li>
              </ul>
            </div>
          </div>
    </div>

   
    <div className="cta-container">
    <div className="cta-content">
      <h2>Invest in our experienced and certified professionals now.</h2>
      <p>Talk to our experts.</p>
      <button class='button-contact-us' onClick={handleButtonClick}>
        <svg
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
            fill="currentColor"
          ></path>
        </svg>
        <span>Contact Us</span>
      </button>
    </div>
  </div>
        
      </div>
      
    );
};

export default Optimization;
