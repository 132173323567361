import React, { useState, useEffect } from 'react';
import './Contact_Us.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';

const ContactSection = () => {
  const [messageSent, setMessageSent] = useState(false); // State to track message status

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "b8a6d861-2a5d-4932-b2a6-2b4791c31273");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: json
    }).then((res) => res.json());

    if (res.success) {
      setMessageSent(true); // Show the success message
    }
  };

  // Hide the success message after 10 seconds
  useEffect(() => {
    if (messageSent) {
      const timer = setTimeout(() => {
        setMessageSent(false);
      }, 5000); // 10 seconds

      return () => clearTimeout(timer); // Cleanup on unmount
    }
  }, [messageSent]);

  return (
    <div className="container-contact_us">
      <section className="imageSection">
        <div className="textOverlay">
          <h1>Contact Us</h1>
          <p>
            Start your transformation journey with <span className='para-in-black-words'>JS Dynamics!</span> Get in touch with our consultants should you have any questions about our services and solutions.
          </p>
        </div>
        <div className="wave-container">
          <svg
            className="waves"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28"
            preserveAspectRatio="none"
            shapeRendering="auto"
          >
            <defs>
              <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
              <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
              <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
              <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
              <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
          </svg>
        </div>
      </section>

      <main className="row">
        <section className="col left">
          <div className="contactTitle">
            <h2>Get In Touch</h2>
            <p>Send us a Message!</p>
          </div>

          <div className="contactInfo">
            {/* <div className="iconGroup">
              <div className="icon">
                <FontAwesomeIcon icon={faPhone} />
              </div>
              <div className="details">
                <span>Phone</span>
                <span>+00 110 111 00</span>
              </div>
            </div> */}

            <div className="iconGroup">
              <div className="icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <div className="details">
                <span>Email</span>
                <span>sales@jnsdynamics.com</span>
              </div>
            </div>

            <div className="iconGroup">
              <div className="icon">
                <FontAwesomeIcon icon={faLocationDot} />
              </div>
              <div className="details">
                <span>Location</span>
                <span>India</span>
              </div>
            </div>
          </div>
        </section>

        <section className="col right">
          <form className="messageForm" onSubmit={onSubmit}>
            <div className="inputGroup halfWidth">
              <input type="text" name="name" required />
              <label>Your Name</label>
            </div>

            <div className="inputGroup halfWidth">
              <input type="email" name="email" required />
              <label>Email</label>
            </div>

            <div className="inputGroup fullWidth">
              <input type="text" name="subject" required />
              <label>Subject</label>
            </div>

            <div className="inputGroup fullWidth">
              <textarea name="message" required></textarea>
              <label>Say Something</label>
            </div>

            <div className="inputGroup fullWidth">
              <button type="submit">Send Message</button>
            </div>
          </form>
        </section>
      </main>

      {/* Success message overlay */}
      {messageSent && (
        <div className="message-overlay">
          <h2>Message sent successfully!</h2>
        </div>
      )}
    </div>
  );
}

export default ContactSection;
