import React, { useRef, useEffect } from "react";
import './Implementation.css';  // Import CSS styles
import { Link } from 'react-router-dom';
import image1 from './image3.jpg';
import image2 from './image4.jpg';
import image3 from './image5.jpg';
import image4 from './image6.jpg';
// import image2 from '../Lease_Accounting/Lease_Accounting3rd.jpg';

const Esg_Reporting = () => {
    const headingRef = useRef(null);
    const descriptionRef = useRef(null);
     // New ref for content-section-2
    const handleButtonClick = () => {
      window.location.href = "/contactus"; // External URL
    };
    
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("in-view");
            } else {
              entry.target.classList.remove("in-view");
            }
          });
        },
        {
          threshold: 0.1, // Adjust the threshold to ensure animations trigger earlier
        }
      );

      // Observe all relevant elements
      const refs = [headingRef, descriptionRef]; // Added textContentRef2
      refs.forEach((ref) => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      });

      // Cleanup observer on component unmount
      return () => {
        refs.forEach((ref) => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        });
      };
    }, []);

    return (
      <div>
        <div className="background-c-services">
          <div className="contents" ref={headingRef}>
            <div className="titles">Implementation</div>
            <div className="descriptions" ref={descriptionRef}>
              Ensure your success with centralized operations by a well-planned implementation of
              OneStream. Benefit from our team’s expertise in a structured implementation,
              making sure that it is rigorously tested, designed, and configured to business requirements
              before deployment.  
              {/* Add your content here */}
            </div>
          </div>
        </div>
        <div class="bottom-design"></div>
        <div className="lease-container">
          <div className="lease-image">
            <img src={image2} className="image-advisory-services" />
            <div className="lease-description">
              <h2>OneStream Implementation</h2>
              <ul>
                <li>Improve your business’s strategies and efforts and solve complex financial consolidation & financial reporting tasks with OneStream solutions. With <span className="para-in-black-words">JS DYNAMICS's</span> implementation of CPM solutions, you can leverage on data-driven decisions to achieve organizational success by assessing your current game plan.</li>
              </ul>
            </div>
          </div>
    </div>
    <section className="implementation-methodology-container">
      <div className="implementation-methodology-content">
        <div className="implementation-methodology-text">
          <h2 className="implementation-methodology-heading">OneStream Implementation Methodology</h2>
          <p className="implementation-methodology-paragraph">
          At <span className="para-in-black-words">JS DYNAMICS</span>, we follow a structured and proven approach to implementing OneStream, ensuring seamless integration and optimized performance for your financial processes.
          </p>
          {/* <p className="implementation-methodology-paragraph">
          </p> */}
          <ul className="implementation-methodology-list">
            <li><span className="para-in-black-words">Discovery and Planning:</span> We assess your business needs and define project objectives, identifying gaps in your current processes.</li>
            <li><span className="para-in-black-words">Solution Design:</span> Our team customizes the OneStream architecture and integrates data from your existing systems.</li>
            <li><span className="para-in-black-words">Configuration and Development:</span> We set up the platform based on your requirements, automating workflows and reporting.</li>
            <li><span className="para-in-black-words">Testing and Validation:</span> Rigorous testing ensures accuracy and performance, followed by user acceptance testing to confirm readiness.</li>
            <li><span className="para-in-black-words">Training and Go-Live:</span> We provide comprehensive training and support during the transition to ensure a smooth go-live.</li>
            <li><span className="para-in-black-words">Ongoing Support:</span> After implementation, we offer continuous support and optimization to adapt to your evolving needs.</li>
          </ul>
        </div>
        <div className="implementation-methodology-image-container">
          <img 
            src={image3}
            alt="Person with data visualization" 
            className="implementation-methodology-image" 
          />
        </div>
      </div>
    </section>
    <div className="card-key-fun">
      <div className="key-fun-image">
        <img 
          src={image4} 
          alt="Card illustration" 
          className="image" 
        />
      </div>
      <div className="key-fun-content">
        <h2 className="key-fun-heading">Benefits of Successful OneStream Implementation</h2>
        <ul className="key-fun-list">
          <li><span className="para-in-black-words">Enhanced Accuracy:</span> Automated data consolidation reduces errors and improves the reliability of financial reports.</li>
          <li><span className="para-in-black-words">Increased Efficiency:</span> Streamlined processes accelerate budgeting, forecasting, and reporting, saving valuable time.</li>
          <li><span className="para-in-black-words">Real-Time Insights:</span> Access to up-to-date data facilitates informed decision-making and quick responses to changes.</li>
          <li><span className="para-in-black-words">Improved Collaboration:</span> OneStream fosters teamwork across departments, ensuring alignment with financial goals.</li>
          <li><span className="para-in-black-words">Scalability:</span> The platform grows with your business, allowing for easy updates and expansions.</li>
          <li><span className="para-in-black-words">Regulatory Compliance:</span> Built-in features support adherence to financial regulations and standards.</li>
          <li><span className="para-in-black-words">User-Friendly Interface:</span>An intuitive design boosts user adoption and minimizes training time.</li>
          <li><span className="para-in-black-words">Cost Savings:</span> Increased efficiency and reduced errors lead to lower operational costs over time.</li>
        </ul>
      </div>
    </div>
    <div className="cta-container">
    <div className="cta-content">
      <h2>Invest in our experienced and certified professionals now.</h2>
      <p>Talk to our experts.</p>
      <button class='button-contact-us' onClick={handleButtonClick}>
        <svg
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
            fill="currentColor"
          ></path>
        </svg>
        <span>Contact Us</span>
      </button>
    </div>
  </div>
        
      </div>
      
    );
};

export default Esg_Reporting;
