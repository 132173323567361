import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./Mains.css"; // Assuming this is the CSS file you're using
import image1 from './5-image/image1.png';
import image2 from './5-image/image2.png';
import image3 from './5-image/image3.jpg';
import image4 from './5-image/image4.png';
import image5 from './5-image/image5.png';
import image6 from './Lease_Accounting.jpg';
import careerimg from './career-img-mains/photo.jpg';


import cardImage6 from './cards_13_img/img1.jpg';
import cardImage7 from './cards_13_img/img2.jpg';
import cardImage8 from './cards_13_img/img3.jpg';
import cardImage9 from './cards_13_img/img4.jpg';
import cardImage10 from './cards_13_img/img5.jpg';
import cardImage11 from './cards_13_img/img6.jpg';
import cardImage12 from './cards_13_img/img7.jpg';
import cardImage13 from './cards_13_img/img8.jpg';
import cardImage14 from './cards_13_img/img9.jpg';
import cardImage15 from './cards_13_img/img10.jpg';
import cardImage16 from './cards_13_img/img11.jpg';
import cardImage17 from './cards_13_img/img12.jpg';
import cardImage18 from './cards_13_img/img13.jpg';
const allCases = [
  {
    title: "Lease Accounting",
    blurb:
      "Simplify your lease accounting process and save time automating your financial data using one integrated application.",
    link: "/Solutions/Lease-Accounting",
  },
  {
    title: "Profitability and Cost Analysis",
    blurb:
      "With OneStream, we create a flexible allocation model that provides you with real insight into your organisation’s profitability potential.",
    link: "/Solutions/profitablity-and-cost-analysis",
  },
  {
    title: "Tax Provisioning",
    blurb:
      "Unite corporate finance with your tax processes using the tax provisioning application developed by JS Dynamics.",
    link: "/Solutions/beps_pillar_two_global_tax_management",
  },
  {
    title: "Advisory",
    blurb:
      "Unlock your business potential and gain an edge in today’s competitive business environment. We are here to help you grow with our reliable and goal-driven advisory.",
    link: "/Services/Advisory-Services",
  },
  {
    title: "Implementation",
    blurb:
      "Ensure your success with centralized operations by a well-planned implementation of OneStream and Microsoft Dynamics 365.",
    link: "/Services/Implementation",
  },
  {
    title: "Application Lifecycle Management",
    blurb:
      "Maximize the value of your company's technology investments by entrusting the continuous maintenance and support of your systems and applications to our team of experts.",
    link: "/Services/Application-Lifecycle-Management",
  },
];
const cardDataSection2 = [
  { image: cardImage6, title: "Financial Close & Consolidation", description: "Conquer the complexity of the financial close and consolidation process and deliver rapid insights to stakeholders." },
  { image: cardImage7, title: "Planning, Budgeting & Forecasting", description: "Respond quickly to new market opportunities or threats with a unified platform across Sales, Marketing, Supply Chain and FP&A." },
  { image: cardImage8, title: "Financial Signaling", description: "Respond quickly to new market opportunities or threats with a unified platform across Sales, Marketing, Supply Chain and FP&A." },
  { image: cardImage9, title: "Profitability and Cost Analysis", description: "Create a flexible allocation model and generate insight into the profitability of your organization." },
  { image: cardImage10, title: "Reporting & Analysis", description: "Deliver the right information, in the right format, at the right time with a unified CPM platform." },
  { image: cardImage11, title: "Financial Data Quality", description: "Make data-driven decisions based on accurate financial and operating results." },
  { image: cardImage12, title: "Account Reconciliation", description: "Conquer complexity and improve the integrity of financial results by aligning your account reconciliations." },
  { image: cardImage13, title: "People Planning", description: "Empower managers by using dynamic calculations to project the costs of new hires and other related expenses." },
  { image: cardImage14, title: "xP&A", description: "Deliver the potential of unified planning across the enterprise by using the next evolution of processes and tools." },
  { image: cardImage15, title: "Capital Planning", description: "Streamline the planning of capital assets and accountability while providing a better control environment across the organization." },
  { image: cardImage16, title: "Sales Planning", description: "Align customers, products, pricing and sales targets with financial plans at the speed of the business while driving performance." },
  { image: cardImage17, title: "Transaction Matching", description: "Match transactional data from multiple sources and improve the accuracy of financial statements." },
  { image: cardImage18, title: "Compliance Solutions", description: "Stay in compliance with US GAAP, IFRS and other local statutory requirements with built-in financial intelligence." },
];
const Card = ({ image, title, description, hasReadMore, link }) => (
  <div className="cards-solutions">
    <div className="cards-solutions-image-container">
      <img src={image} alt={title} className="cards-solutions-image" />
    </div>
    <div className="cards-solutions-content">
      <h3 className="cards-solutions-title">{title}</h3>
      <p className="cards-solutions-description">{description}</p>
      {hasReadMore && link && <a href={link} className="cards-solutions-read-more">READ MORE</a>}
    </div>
  </div>
);

// Cards component
const Cards = ({ cardsToShow, cardData }) => {
  useEffect(() => {
    const cards = document.querySelectorAll(".cards-solutions");
    cards.forEach((card) => {
      card.style.opacity = "0"; 
      card.style.transition = "opacity 1s ease, transform 1s ease";
      card.style.transform = "translateY(100px)"; 

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            card.style.opacity = "1"; 
            card.style.transform = "translateY(0)"; 
            observer.unobserve(card); 
          }
        });
      }, {
        threshold: 0.1,
      });
      observer.observe(card);
    });
  }, []);
  
  return (
    <div className="cards-container">
      {cardData.map((card, index) => (
        <Card key={index} {...card} />
      ))}
    </div>
  );
};
const handleButtonClick = () => {
  window.location.href = "/Services"; // External URL
};
const MainAndCases = () => {
  const [visibleCases, setVisibleCases] = useState(0); 
  const headingRef = useRef(null);
  const headingRef2 = useRef(null);
  const headingRef3 = useRef(null);
  const smallHRef = useRef(null);
  const imgRefs = useRef([]); 
  
  const loadMoreCases = () => {
    if (visibleCases < allCases.length) {
      setVisibleCases((prevVisibleCases) => prevVisibleCases + 3); 
    }
  };

  const handleScroll = () => {
    if (headingRef.current) {
      const headingPosition = headingRef.current.getBoundingClientRect().bottom;
      const screenBottom = window.innerHeight;

      if (headingPosition <= screenBottom) {
        loadMoreCases();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [visibleCases]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("in-view");
        } else {
          entry.target.classList.remove("in-view");
        }
      });
    }, {
      threshold: 0.1, 
    });

    if (headingRef.current) observer.observe(headingRef.current);
    if (headingRef2.current) observer.observe(headingRef2.current);
    if (headingRef3.current) observer.observe(headingRef3.current);
    if (smallHRef.current) observer.observe(smallHRef.current);
    
    imgRefs.current.forEach((imgRef) => {
      if (imgRef) observer.observe(imgRef);
    });

    return () => {
      if (headingRef.current) observer.unobserve(headingRef.current);
      if (headingRef2.current) observer.unobserve(headingRef2.current);
      if (headingRef3.current) observer.unobserve(headingRef3.current);
      if (smallHRef.current) observer.unobserve(smallHRef.current);
      imgRefs.current.forEach((imgRef) => {
        if (imgRef) observer.unobserve(imgRef);
      });
    };
  }, []);

  return (
    <div className="main-cases-wrapper">
      <h1 className="heading" ref={headingRef}>
        Addressing your Business Requirements
      </h1>
      <h3 className="smallH" ref={smallHRef}>
        Integrate your finance process with automated services
      </h3>
      
      <Cards cardsToShow={13} cardData={cardDataSection2} />  
      {/* <div className="grid">
        {allCases.slice(0, visibleCases).map((item, index) => (
          <div key={index} className="grid__item">
            <div className="item__content">
             <div className="font-bold mb-2">{item.title}</div>
             <p className="flex-grow mb-2">{item.blurb}</p>
             <a href={item.link}>Learn More</a> 
            </div>
          </div>
        ))}
        {visibleCases < allCases.length && <p>Loading more...</p>}
      </div> */}
          {/* HERE */}
      <h1 className="heading" ref={headingRef2}>
          Introduction to our company
      </h1>
      <p className="para-intro-main">Our purpose is to deliver quality end-to-end finance business services and solutions that cater to your needs. The business insights solutions and services we provide are designed to empower our customers to respond to ever-changing needs and challenges. <span className="shine3">JS Dynamics </span>has grown into one of the most trusted partners in shaping the future of finance.</p>

      {/* <div className="img-container">
        {[image1, image2, image3, image4, image5].map((src, idx) => (
          <div className="img-item" ref={(el) => imgRefs.current[idx] = el} key={idx}>
            <img src={src} className="img-5" alt={`Description ${idx + 1}`} />
            <div className="img-description">
              {[
                "OneStream Diamond Partner",
                "OneStream Development Partner",
                "Over 240+ projects in 40 countries",
                "11 locations in India, Europe, and Middle East",
                "220+ Dedicated Professionals"
              ][idx]}
            </div>
          </div>
        ))}
      </div> */}
      <h1 className="heading" ref={headingRef3}>
          Overview of OneStream EPM Services
      </h1>
      <p className="para-intro-main">OneStream EPM (Enterprise Performance Management) is a modern platform designed to help organizations streamline and unify their financial processes. It provides tools for financial reporting, budgeting, planning, and data integration, all in one system.</p>
      
      <div className="lease-container">
          <div className="lease-image">
            <img src={image6} alt="Lease Accounting" />
            <div className="lease-description key-fun-home">
              <h2>Key Functionalities</h2>
              <ul>
                <li><span className="para-in-black-words">Financial Consolidation and Reporting:</span> Consolidates financial data and generates accurate, compliant reports.</li>
                <li><span className="para-in-black-words">Budgeting, Planning, and Forecasting:</span>Provides tools for creating and managing budgets, plans, and forecasts.</li>
                <li><span className="para-in-black-words">Data Integration:</span> Integrates data from multiple sources into a unified system.</li>
                <li><span className="para-in-black-words">Account Reconciliation:</span>Simplifies and automates the account reconciliation process.</li>
                {/* <li><span className="para-in-black-words">Extensibility:</span>Allows customization and extension with additional solutions from its marketplace.</li> */}
              </ul>
            <button class="btn-key-fun" onClick={handleButtonClick}>
            <span class="span-mother">
              <span>L</span>
              <span>E</span>
              <span>A</span>
              <span>R</span>
              <span>N</span> 
              <span id="space"></span>
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>E</span>
            </span>
            <span class="span-mother2">
              <span>L</span>
              <span>E</span>
              <span>A</span>
              <span>R</span>
              <span>N</span>
              <span id="space"></span>
              {/* <span> </span> */}
              <span>M</span>
              <span>O</span>
              <span>R</span>
              <span>E</span>
            </span>
          </button>
            </div>
            
          </div>
        </div>
      
      <div className="callout-container">
        <img src={careerimg} alt="Group Image" className="background-images"/>
        <div className="callout-box">
          <h2 className="callout-h2">Be part of <span class="shine2">JS DYNAMICS!</span></h2>
          <p>Are you looking to kickstart your CPM / EPM career or want to work on the most prestigious projects across the globe? As a trusted advisor to some of the world’s most renowned brands, we are continuously seeking exceptional individuals to become part of our dynamic team. Your opportunity awaits with <span className="shine5">JS Dynamics</span>!</p>
          <a href="#" className="callout-button">DISCOVER MORE</a>
        </div>
      </div>
    </div>

  );
};

export default MainAndCases;