import React, { useRef, useEffect } from "react";
import './Lease_Accounting.css';  // Import CSS styles
import { Link } from 'react-router-dom';
import image1 from './image1.jpeg';
import image2 from './Lease_Accounting3rd.jpg';

const LeaseAccounting = () => {
    const headingRef = useRef(null);
    const descriptionRef = useRef(null);
    const textContentRef = useRef(null);
    const imageContentRef = useRef(null);
    const textContentRef2 = useRef(null); // New ref for content-section-2
    
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("in-view");
            } else {
              entry.target.classList.remove("in-view");
            }
          });
        },
        {
          threshold: 0.1, // Adjust the threshold to ensure animations trigger earlier
        }
      );

      // Observe all relevant elements
      const refs = [headingRef, descriptionRef, textContentRef, imageContentRef, textContentRef2]; // Added textContentRef2
      refs.forEach((ref) => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      });

      // Cleanup observer on component unmount
      return () => {
        refs.forEach((ref) => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        });
      };
    }, []);

    return (
      <div>
        <div className="background-cS">
          <div className="contents" ref={headingRef}>
            <div className="titles">JS DYNAMICS's OneStream Lease Accounting Solution</div>
            <div className="descriptions" ref={descriptionRef}>
              {/* Add your content here */}
            </div>
          </div>
        </div>
        <div className="content-section">
          <div className="text-content" ref={textContentRef}>
            <h2>Simplify Your Lease Accounting Process</h2>
            <p>
              The new IFRS and ASC lease accounting standards add a new layer of complexity to your financial data 
              and reporting requirements. The process can be cumbersome and requires valuable time of your finance 
              professionals. Free up this time by implementing a solution that automates the whole process, resulting 
              in timely and efficient reporting processes enabling finance to spend more time on analysis to be the 
              true business partner of your organization.<span className="para-in-black-words">JS Dynamics</span> Solutions has a track record of supporting the Office 
              of Finance worldwide with our unique Lease Accounting Solution.
            </p>
          </div>
          <div className="image-content" ref={imageContentRef}>
            <img src={image1} alt="Lease Accounting" />
          </div>
        </div>

        <div className="lease-container">
          <div className="lease-image">
            <img src={image2} alt="Lease Accounting" />
            <div className="lease-description">
              <h2>Key Functionalities</h2>
              <ul>
                <li>One central register for all lease contracts</li>
                <li>Automated journal generation supporting both IFRS 16 and ASC 842 calculations</li>
                <li>Easily calculate the lease liability and related impacts of your lease portfolio</li>
                <li>Instant overview of all leases</li>
                <li>Out of the box reports and disclosures to report under the new standards</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="content-section-2">
          <div className="text-content-2" ref={textContentRef2}>
            <h2>A JS DYNAMICS Solutions Original</h2>
            <p>
              The Lease Accounting solution was built by <span className="para-in-black-words">JS Dynamics</span> Solutions because we foresaw difficulties with the new IFRS 16 accounting standard. Shortly thereafter our solution was adopted by a major global OneStream client with subsequent other clients who also dealt with IFRS 16 calculation difficulties. Our solution was able to help these customers deal with a multitude of different leases and calculation variables. Since our first project the solution had continued to mature and now is able to address requirements for companies across all branches and industries.
            </p>
          </div>
        </div>

        {/* Timeline Section */}
        <section className="design-section">
          <div className="timeline">
            <div className="timeline-empty"></div>
            <div className="timeline-middle">
              <div className="timeline-circle"></div>
            </div>
            <div className="timeline-component timeline-content">
              <h3>Look at your results from another perspective</h3>
              <p>With one click you look at your results from the perspective of another Lease Accounting rule, choose from: ASC 842 and IFRS 16. Moreover, with drag and drop and pivot-table functionality you can analyze results thoroughly.</p>
            </div>
            <div className="timeline-component timeline-content">
              <h3>Guided workflow means reliable data</h3>
              <p>Not only are users guided through the process when loading data, the software also immediately detects when figures don’t add up.</p>
            </div>
            <div className="timeline-middle">
              <div className="timeline-circle"></div>
            </div>
            <div className="timeline-empty"></div>

            <div className="timeline-empty"></div>
            <div className="timeline-middle">
              <div className="timeline-circle"></div>
            </div>
            <div className="timeline-component timeline-content">
              <h3>Help your auditor out!​</h3>
              <p>The OneStream Lease Accounting solution services your auditor directly. As the figures are coming from one single source, workflow validations enable you to reconcile what you report and drill down to reconciliations directly from financial reports. The audit trail is logged by defined users. Your auditor will thank you.​</p>
            </div>
          </div>
        </section>
      </div>
    );
};

export default LeaseAccounting;
