import React, { useState, useEffect, useRef } from "react";
import './About_Us.css';
import image1 from '../Mains/5-image/image1.png';
import image2 from '../Mains/5-image//image2.png';
import image3 from '../Mains/5-image/image3.jpg';
import image4 from '../Mains/5-image/image4.png';
import image5 from '../Mains/5-image/image5.png';
import image6 from './our-company-img.jpg';
import image7 from './approach.jpg';
// import image8 from './know-more-bckgrnd.webp';
import careerimg from '../Mains/career-img-mains/photo.jpg';
const About_Us_Upper = () => {
  const headingRef = useRef(null);
  const descriptionRef = useRef(null);
  const whoWeAreRef = useRef(null);
  const imgRefs = useRef([]); // Declare imgRefs as an array to store references to images

  const handleButtonClick = () => {
    window.location.href = "/contactus"; // External URL
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }
    if (descriptionRef.current) {
      observer.observe(descriptionRef.current);
    }
    if (whoWeAreRef.current) {
      observer.observe(whoWeAreRef.current);
    }
    // Observe all the image elements
    imgRefs.current.forEach((img) => {
      if (img) observer.observe(img);
    });

    return () => {
      if (headingRef.current) observer.unobserve(headingRef.current);
      if (descriptionRef.current) observer.unobserve(descriptionRef.current);
      if (whoWeAreRef.current) observer.unobserve(whoWeAreRef.current);
      // Unobserve all image elements on cleanup
      imgRefs.current.forEach((img) => {
        if (img) observer.unobserve(img);
      });
    };
  }, []);

  return (
    <div>
      <div class="bottom-designs"></div>
      {/* <div className="background-containers">
        <div className="contents" ref={headingRef}>
          <div className="titles">Our Company</div>
          <div className="descriptions" ref={descriptionRef}>
            Our purpose is to deliver quality end-to-end finance business services
            and solutions that cater to your needs. The business insights solutions
            and services we provide are designed to empower our customers to respond
            to ever-changing needs and challenges. <span className="para-in-black-words">J&S Dynamics</span> has grown into
            one of the most trusted partners in shaping the future of finance.
          </div>
        </div>
      </div> */}
        <div class="fixed-background">
      <div className="who-we-are-section" ref={whoWeAreRef}>
        <h2>Who We Are</h2>
        <p>
          As an organization, we are defined by our principles and business beliefs set forth at the highest level to create a positive impact on the success of our people, partners, and customers.
        </p>
        <br />
        <p>
          We create a culture of safety and trust with prudence. We thrive by cultivation and genuine connections, motivated to preserve – in highlights and lowlights.<span className="shine3">JS Dynamics</span> constantly seeks new ideas with the people we work with.
        </p>
      
        {/* Vision and Mission Section */}
        <hr className="who-we-are-line" />
        <div className="vision-mission-container">
          <div className="vision">
            <h3>Our Vision</h3>
            <p>To give back time to our customers by providing the world with the power of innovative solutions through our global presence, thought leadership, and the use of leading technology.</p>
          </div>
          <div className="mission">
            <h3>Our Mission</h3>
            <p>To provide business insight solutions and services that empower our customers to proactively respond to changes and challenges.</p>
          </div>
        </div>

        {/* Horizontal Line */}

        {/* Additional Description */}
        {/* <hr className="who-we-are-line" />
        <div className="who-we-are-description">
          <p>
            <span className="shine3">JS Dynamics</span> offers full lifecycle: Implementation, Training, Development, and Managed Services. We are a global team of 10+ dedicated experts and professionals that thrive on passion and innovation spread in 5 locations worldwide.
            <br /><br />
            <p>1 year in the business allow us to fulfill implementations and support services in over 3 countries. Our experience is backed up by 2+ projects for over 10 customers in different industries.</p>
          </p>
        </div> */}
        </div>
      </div>
      {/* <div className="img-container">
        {[image1, image2, image3, image4, image5].map((src, idx) => (
          <div className="img-item" ref={(el) => (imgRefs.current[idx] = el)} key={idx}>
            <img src={src} className="img-5" alt={`Description ${idx + 1}`} />
            <div className="img-description">
              {[
                "OneStream Diamond Partner",
                "OneStream Development Partner",
                "Over 240+ projects in 40 countries",
                "11 locations in India, Europe, and Middle East",
                "220+ Dedicated Professionals"
              ][idx]}
            </div>
          </div>
        ))}
      </div> */}
<div className="purpose-container">
  <div className="purpose-text">
    <h3>Our Purpose</h3>
    <p>
      To give back time to our customers by providing the world with the power of innovative solutions 
      through our global presence, thought leadership, and the use of leading technology.
    </p>
  </div>
  <div className="purpose-image">
    <img src={image6} alt="Team" />
  </div>
</div>

<div className="approach-container">
<div className="approach-image">
    <img src={image7} alt="Team" />
  </div>
  <div className="approach-text">
    
    <h3>Our Approach</h3>
    <p>
    We understand that every business has its own set of challenges and objectives. That’s why we take a consultative approach, working closely with you to identify your specific requirements and goals. Whether you’re looking to streamline financial processes, enhance reporting capabilities, or improve forecasting accuracy, our team of experts is dedicated to delivering customized solutions that drive tangible results.
    </p>
        </div>
    </div>
    <div className="callout-container">
        <img src={careerimg} alt="Group Image" className="background-images"/>
        <div className="callout-box">
          <h2 className="callout-h2">Be part of <span className="shine2">JS Dynamics</span>!</h2>
          <p>Are you looking to kickstart your CPM / EPM career or want to work on the most prestigious projects across the globe? As a trusted advisor to some of the world’s most renowned brands, we are continuously seeking exceptional individuals to become part of our dynamic team. Your opportunity awaits with <span className="shine2">JS Dynamics</span>!</p>
          <a href="#" className="callout-button">DISCOVER MORE</a>
        </div>
    </div>

    <div className="know_more">
  {/* <img src={image8} alt="Team" /> */}
  <h3>Want to learn more about <span className="shine3">JS Dynamics</span>? Get in touch with our experts today!
  {/* <br />
  <br /> */}
  
  </h3>
  <button className="explore" onClick={handleButtonClick}>
    <span className="icon-right"></span>
    <span className="icon-right after"></span>
  </button> 
</div>
    </div>
  );
};

export default About_Us_Upper;
