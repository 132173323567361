import React, { useRef, useEffect } from "react";
import './Training.css';  // Import CSS styles
import { Link } from 'react-router-dom';
import image1 from './image1.jpg';
import image2 from './image1.jpg';
import image3 from './traning.jpg';
import image4 from './onestream_logo.png';
import onestream_logo from './onestream_logo.png';
// import image2 from '../Lease_Accounting/Lease_Accounting3rd.jpg';

const Training = () => {
    const headingRef = useRef(null);
    const descriptionRef = useRef(null);
    const textContentRef = useRef(null);
    const imageContentRef = useRef(null);
    const logoContainerRef = useRef(null);

     // New ref for content-section-2
    const handleButtonClick = () => {
      window.location.href = "/contactus"; // External URL
    };
    
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("in-view");
            } else {
              entry.target.classList.remove("in-view");
            }
          });
        },
        {
          threshold: 0.1, // Adjust the threshold to ensure animations trigger earlier
        }
      );

      // Observe all relevant elements
      const refs = [headingRef, descriptionRef,textContentRef,imageContentRef,logoContainerRef]; // Added textContentRef2
      refs.forEach((ref) => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      });

      // Cleanup observer on component unmount
      return () => {
        refs.forEach((ref) => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        });
      };
    }, []);

    return (
      <div>
        <div className="background-c-services-training">
          <div className="contents" ref={headingRef}>
            <div className="titles">Training and Education</div>
            <div className="descriptions" ref={descriptionRef}>
                <span className="shine3">JS DYNAMICS</span> offers a wide range of flexible trainings from beginners to advanced level for OneStream Software delivered by highly professional and certified practitioners. Our trainings are designed to help you achieve finance and IT transformation goals. OneStream authorized training provider and a Diamond partner.
              {/* Add your content here */}
            </div>
          </div>
        </div>
        <div class="bottom-design"></div>
        <div className="logo-container" ref={logoContainerRef}>
          <img src={onestream_logo} className="back-c-img" alt="OneStream Logo" />
        <p>OneStream Training offers a variety of learning options to help users master the platform. From on-demand courses to instructor-led sessions and certification programs, training is designed to cater to all skill levels. Custom training is available to address specific business needs, ensuring teams can maximize OneStream’s capabilities. Users can learn at their own pace or participate in live, hands-on sessions with certified experts. Registration is easy through the OneStream University portal.</p>
        {/* <br/> */}
        {/* <p><span className="para-in-black-words">JS DYNAMICS</span> is also a OneStream Diamond Training Partner, which means that we are one of the few partners in the world authorized to deliver official OneStream courses as closed training at customers' premises and even in languages different from English. We are saving our customers the hassle and cost of traveling and providing the comfort of doing the courses at "home."</p> */}
      </div>

      <div className="onestream-wrapper">
      {/* Left Side: Image */}
      <div className="onestream-image-container">
        <img 
          src={image3}
          alt="OneStream Implementation" 
          className="onestream-image" 
        />
      </div>

      {/* Right Side: Text and Content */}
      <div className="onestream-content-container">
        <img 
          src={image4}
          alt="OneStream Logo" 
          className="onestream-logo" 
        />
        <h2 className="onestream-heading">OneStream Training</h2>
        <p className="onestream-paragraph">
        OneStream offers a range of training programs designed to meet the needs of users at all levels, from beginners to advanced professionals. Whether you're looking to enhance your skills with self-paced courses or require customized training for your team, OneStream provides flexible options to ensure you get the most out of the platform.
        </p>
        <ul className="onestream-list">
          <li className="onestream-list-item">Visit OneStream University: Access training options on the official portal.</li>
          <li className="onestream-list-item">Select a Program: Choose between on-demand, instructor-led, certification, or custom training.</li>
          <li className="onestream-list-item">Schedule or Register: Start on-demand courses immediately, or schedule instructor-led sessions. For custom training, contact OneStream directly.</li>
          <li className="onestream-list-item">Receive Confirmation: After registering, get confirmation with course details via email.</li>
        </ul>
        <button className="onestream-learn-more-btn">Learn More</button>
      </div>
    </div>

      </div>
      
    );
};

export default Training
