import React, { useRef, useEffect } from "react";
import './profitability_and_cost_analysis.css';  // Import CSS styles
import { Link } from 'react-router-dom';
import image1 from './image1.jpg';
import image2 from './image2.jpeg';
import image3 from './image3.jpg';
import { FaDatabase, FaTools, FaCalculator, FaComments, FaHandshake } from "react-icons/fa";
const Esg_Reporting = () => {
    const headingRef = useRef(null);
    const descriptionRef = useRef(null);
    const textContentRef = useRef(null);
    const imageContentRef = useRef(null);
 // New ref for content-section-2
    const textContentsRef = useRef(null);  // Unique ref for content section 1
    const textContentRef2 = useRef(null); // Added ref for the missing .text-contents
    const textContentRef3 = useRef(null);
    const textContentRef4 = useRef(null);
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("in-view");
            } else {
              entry.target.classList.remove("in-view");
            }
          });
        },
        {
          threshold: 0.1, // Adjust the threshold to ensure animations trigger earlier
        }
      );

      // Observe all relevant elements
      const refs = [headingRef, descriptionRef, textContentRef, imageContentRef, textContentRef2, textContentsRef,textContentRef3,textContentRef4]; // Added textContentsRef
      refs.forEach((ref) => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      });

      // Cleanup observer on component unmount
      return () => {
        refs.forEach((ref) => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        });
      };
    }, []);

    return (
      <div>
        <div className="background-cS">
          <div className="contents" ref={headingRef}>
            <div className="titles">Profitability and Cost
            Analysis </div>

          </div>
        </div>
        
        <div className="content-section">
          
          <div className="text-content" ref={textContentRef}>
            <h2>Drive Business Growth with OneStream's Profitability and Cost Analysis</h2>
            <p>
            Have the power to create a flexible allocation model and generate real insight into the profitability of your organization, helping you to drive the business through any scenario.</p>
          </div>
          <div className="image-content" ref={imageContentRef}>
            <img src={image1} alt="Lease Accounting" />
        
          </div>
          </div>
          <div className="content-section">
        <div className="text-content text-contents" ref={textContentsRef}>
            <h2>How does it work?</h2>
            <p>
            Users are presented with an allocation dashboard where the entire setup can be built. Drivers allow users to compose allocation rules and determine which entities, accounts and scenarios should be run. This can be fully personalized creating a flexible, comprehensive and user-friendly experience.
            </p>
            <p>There is an option to run the allocation during regular financial processes or to trigger via the push of a button. Although it is fully flexible, the goal is to never look backwards again (unless adjustments are required) and analyze the allocation outcomes every period.</p>
           </div>
        </div>
    
    <div className="lease-container profitability-container">
          <div className="lease-image profitability-image">
            <img src={image3} alt="Lease Accounting" />
            <div className="lease-description profitability-description">
              <h2>Key Functionalities</h2>
              <ul>
                <li>Profitability insight by product, service, or other areas to steer the organization</li>
                <li>Integration with Actuals and Budget within OneStream Provide accurate allocated figures during volatile</li>
                <li>Rapid, insightful reporting</li>
                <li>Flexibility to make changes or additions to the model</li>
                <li>Allocations can be amended over time while maintaining historic data.</li>
                <li>Can be part of a normal close process</li>
                <li>Can be applied to multiple scenarios to enable comparison/variance reporting</li>
                <li>Analyze at the right level of detail with drill-down functionality</li>
                <li>Reconciliation monitored through the dashboard</li>
                <li>Audit trail capabilities</li>
                <li>Central maintenance or maintenance at an entity level</li>
                </ul>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Esg_Reporting;
