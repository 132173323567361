import React, { useRef, useEffect } from "react";
import './All_Solutions.css';  // Import CSS styles
import { Link } from 'react-router-dom';
// Import images
import onestream_logo from './OneStream_Brandmark.png';
import cardImage1 from './Lease-Accounting-1.jpg';
import cardImage2 from './ESG-1.jpg';
import cardImage3 from './Profitability-and-Cost-Analysis-1.jpg';
import cardImage4 from './Tax-Provisioning-1.jpg';
import cardImage5 from './Tax_Pillar.jpg';

import cardImage6 from './cards_13_img/img1.jpg';
import cardImage7 from './cards_13_img/img2.jpg';
import cardImage8 from './cards_13_img/img3.jpg';
import cardImage9 from './cards_13_img/img4.jpg';
import cardImage10 from './cards_13_img/img5.jpg';
import cardImage11 from './cards_13_img/img6.jpg';
import cardImage12 from './cards_13_img/img7.jpg';
import cardImage13 from './cards_13_img/img8.jpg';
import cardImage14 from './cards_13_img/img9.jpg';
import cardImage15 from './cards_13_img/img10.jpg';
import cardImage16 from './cards_13_img/img11.jpg';
import cardImage17 from './cards_13_img/img12.jpg';
import cardImage18 from './cards_13_img/img13.jpg';

const handleButtonClick = () => {
  window.location.href = "/contactus"; // External URL
};

// Card component
const Card = ({ image, title, description, hasReadMore, link }) => (
  <div className="cards-solutions">
    <div className="cards-solutions-image-container">
      <img src={image} alt={title} className="cards-solutions-image" />
    </div>
    <div className="cards-solutions-content">
      <h3 className="cards-solutions-title">{title}</h3>
      <p className="cards-solutions-description">{description}</p>
      {hasReadMore && link && <a href={link} className="cards-solutions-read-more">READ MORE</a>}
    </div>
  </div>
);

// Cards component
const Cards = ({ cardsToShow, cardData }) => (
  <div className="cards-container">
    {cardData.slice(0, cardsToShow).map((card, index) => (
      <Card key={index} {...card} hasReadMore={cardsToShow === 5 ? index < 5 : false} />
    ))}
  </div>
);

// Call to Action component
const CallToAction = () => (
  <div className="cta-container">
    <div className="cta-content">
      <h2>Start your journey with us.</h2>
      <p>Talk to our experts.</p>
      <button class='button-contact-us' onClick={handleButtonClick}>
        <svg
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
            fill="currentColor"
          ></path>
        </svg>
        <span>Contact Us</span>
      </button>
    </div>
  </div>
);

// Main component
const OneStreamSolutions = () => {
  const headingRef = useRef(null);
  const descriptionRef = useRef(null);
  const logoContainerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          } else {
            entry.target.classList.remove("in-view");
          }
        });
      },
      {
        threshold: 0.2, // Adjusted to trigger earlier
      }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }
    if (descriptionRef.current) {
      observer.observe(descriptionRef.current);
    }
    if (logoContainerRef.current) {
      observer.observe(logoContainerRef.current);
    }

    return () => {
      if (headingRef.current) observer.unobserve(headingRef.current);
      if (descriptionRef.current) observer.unobserve(descriptionRef.current);
      if (logoContainerRef.current) observer.unobserve(logoContainerRef.current);
    };
  }, []);

  // Define card data for both sections
  const cardDataSection1 = [
    { image: cardImage1, title: "Lease Accounting", description: "Save time by implementing a solution that automates all financial data and reporting requirements.", link: "/Solutions/Lease-Accounting" },
    { image: cardImage2, title: "ESG Reporting", description: "Add ESG Reporting to your OneStream platform to take control of your ESG Score.",link: "/Solutions/Esg-Reporting" },
    { image: cardImage3, title: "Tax Provisioning", description: "Our tax provisioning solution will unify these functions and improve efficiency, accuracy, and speed.",link: "/Solutions/beps_pillar_two_global_tax_management" },
    { image: cardImage4, title: "Profitability and Cost Analysis", description: "Create a flexible allocation model and generate insight into the profitability of your organization.",link: "/Solutions/profitablity-and-cost-analysis" },
    { image: cardImage5, title: "Tax Pillar 2", description: "The global tax landscape is rapidly evolving, and the BEPS initiative by the OECD is at the forefront of this transformation.",link: "/Solutions/beps_pillar_two_global_tax_management" },
  ];
  const cardDataSection2 = [
    { image: cardImage6, title: "Financial Close & Consolidation", description: "Conquer the complexity of the financial close and consolidation process and deliver rapid insights to stakeholders." },
    { image: cardImage7, title: "Planning, Budgeting & Forecasting", description: "Respond quickly to new market opportunities or threats with a unified platform across Sales, Marketing, Supply Chain and FP&A." },
    { image: cardImage8, title: "Financial Signaling", description: "Respond quickly to new market opportunities or threats with a unified platform across Sales, Marketing, Supply Chain and FP&A." },
    { image: cardImage9, title: "Profitability and Cost Analysis", description: "Create a flexible allocation model and generate insight into the profitability of your organization." },
    { image: cardImage10, title: "Reporting & Analysis", description: "Deliver the right information, in the right format, at the right time with a unified CPM platform." },
    { image: cardImage11, title: "Financial Data Quality", description: "Make data-driven decisions based on accurate financial and operating results." },
    { image: cardImage12, title: "Account Reconciliation", description: "Conquer complexity and improve the integrity of financial results by aligning your account reconciliations." },
    { image: cardImage13, title: "People Planning", description: "Empower managers by using dynamic calculations to project the costs of new hires and other related expenses." },
    { image: cardImage14, title: "xP&A", description: "Deliver the potential of unified planning across the enterprise by using the next evolution of processes and tools." },
    { image: cardImage15, title: "Capital Planning", description: "Streamline the planning of capital assets and accountability while providing a better control environment across the organization." },
    { image: cardImage16, title: "Sales Planning", description: "Align customers, products, pricing and sales targets with financial plans at the speed of the business while driving performance." },
    { image: cardImage17, title: "Transaction Matching", description: "Match transactional data from multiple sources and improve the accuracy of financial statements." },
    { image: cardImage18, title: "Compliance Solutions", description: "Stay in compliance with US GAAP, IFRS and other local statutory requirements with built-in financial intelligence." },
  ];

  return (
    <div>
      {/* <div className="background-c">
        <div className="contents" ref={headingRef}>
          <div className="titles">OneStream Solutions</div>
          <div className="descriptions" ref={descriptionRef}>
            OneStream is designed to assist finance and accounting professionals in automating and optimizing various financial processes, including financial consolidation, budgeting and forecasting, financial reporting, data integration, and more. This software aims to simplify complex financial tasks, enhance data accuracy, and support better decision-making within organizations.
          </div>
        </div>
      </div> */}
      <div className="background-allsolutions">
        <div className="contents" ref={headingRef}>
          <div className="titles">OneStream Solutions</div>
          <div className="descriptions" ref={descriptionRef}>
          OneStream is designed to assist finance and accounting professionals in automating and optimizing various financial processes, including financial consolidation, budgeting and forecasting, financial reporting, data integration, and more. This software aims to simplify complex financial tasks, enhance data accuracy, and support better decision-making within organizations.
          </div>
        </div>
      </div>
      <div class="bottom-design"></div>
      <div className="logo-container" ref={logoContainerRef}>
        <a href="https://www.onestream.com/" target="_blank" rel="noopener noreferrer">
          <img src={onestream_logo} className="back-c-img" alt="OneStream Logo" />
        </a>
        <p>OneStream Software is a company that specializes in providing Corporate Performance Management (CPM) and Financial Planning & Analysis (FP&A) software solutions. With JS Dynamics as the implementation partner, we are stronger together to deliver your Finance Transformation initiative to fruition.</p>
        
        <div className="container-onestream">
          <div className="btn">
            <a href="https://www.onestream.com/" target="_blank" rel="noopener noreferrer">DISCOVER ONESTREAM</a>
          </div>
        </div>
      </div>
    {/* THIS IS THE HEADING BEFORE SOLUTIONS CARDS THAT ARE COMMENTED FOR NOW */}
      {/* <div className="hd-desc-under-onestream" >
        <h2>Invest in your financial transformation journey</h2>
        <p>Extend the planning and analysis capabilities of the OneStream platform with our full spectrum of OneStream solutions that can be combined tailored to your business goals.</p>
      </div> */}

     
      {/* THIS IS THE SOLUTIONS 5 CARDS THAT ARE COMMENTED  */}
      {/* <Cards cardsToShow={5} cardData={cardDataSection1} /> */}

      <div className="hd-desc-under-onestream">
        <h2>Streamline your business processes; eliminate multiple module silos</h2>
        <p>Skip the manual steps and save time on dealing with complex financial consolidation and planning with a unified application.</p>
      </div>

      {/* Display all 13 cards with unique images */}
      <Cards cardsToShow={13} cardData={cardDataSection2} />

      {/* Call to Action */}
      <CallToAction />
    </div>
  );
};

export default OneStreamSolutions;