import React, { useEffect, useRef, useState } from 'react'; 
import { useNavigate } from 'react-router-dom'; 
import './style.css';
import image1 from './image/img1.jpg';
import image2 from './image/img2.jpg';
import image3 from './image/img3.jpg';
import image4 from './image/img4.jpg';

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesRef = useRef(null);
  const dotsRef = useRef([]);
  const navigate = useNavigate(); 

  const slides = [
    {
      image: image1,
      title: "Take control of your business. Accelerate your finance transformation.",
      description: "Accelerate your finance transformation.",
      buttonText: "Get Started Today",
      onClick: () => navigate('/Services'),
    },
    {
      image: image2,
      title: "Empower Your Enterprise. Optimize Your Financial Solutions.",
      description: "Streamline financial management with OneStream’s unified platform for better business outcomes.",
      buttonText: "Get Started Today",
      onClick: () => navigate('/Solutions'),
    },
    {
      image: image3,
      title: "Transform Your Data. Drive Performance Excellence.",
      description: "Gain real-time insights and improve your business performance with OneStream. ",
      buttonText: "Discover More",
      onClick: () => navigate('/Services'),
    },
    {
      image: image4,
      title: "Unify Your Systems. Simplify Your Finance Operations.",
      description: "OneStream offers complete financial consolidation and planning on a single platform.",
      buttonText: "Explore Solutions",
      onClick: () => navigate('/Solutions'),
    },
  ];

  const showSlide = (index) => {
    if (slidesRef.current) {
      slidesRef.current.style.transform = `translateX(${-index * 100}%)`;
    }
    setCurrentSlide(index);
  };

  const nextSlide = () => {
    const nextIndex = (currentSlide + 1) % slides.length;
    showSlide(nextIndex);
  };

  useEffect(() => {
    showSlide(currentSlide);
    const intervalId = setInterval(nextSlide, 5000);
    return () => clearInterval(intervalId);
  }, [currentSlide]);

  const handleDotClick = (index) => {
    showSlide(index);
  };

  return (
    <div className="slider-container">
      <div className="slider" ref={slidesRef}>
        {slides.map((slide, index) => (
          <div className="slide" key={index}>
            <img src={slide.image} alt={`Slide ${index + 1}`} />
            <div className={`bottom-designss ${currentSlide === index ? 'active' : ''}`}></div>
            <div className={`slide-text ${currentSlide === index ? 'active' : ''}`}>
              <h2>{slide.title}</h2>
              <p>{slide.description}</p>
              <button className="button-slider" onClick={slide.onClick}>
                {slide.buttonText}
                <svg fill="currentColor" viewBox="0 0 24 24" className="icon-slider">
                  <path
                    clipRule="evenodd"
                    d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
      <div className="slider-dots">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentSlide === index ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
            ref={(el) => (dotsRef.current[index] = el)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Slider;
