import React, { useRef, useEffect } from "react";
import './Advisory_Services.css';  // Import CSS styles
import { Link } from 'react-router-dom';
import image1 from './image3.jpg';
// import image2 from '../Lease_Accounting/Lease_Accounting3rd.jpg';

const Esg_Reporting = () => {
    const headingRef = useRef(null);
    const descriptionRef = useRef(null);
     // New ref for content-section-2
    const handleButtonClick = () => {
      window.location.href = "/contactus"; // External URL
    };
    
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("in-view");
            } else {
              entry.target.classList.remove("in-view");
            }
          });
        },
        {
          threshold: 0.1, // Adjust the threshold to ensure animations trigger earlier
        }
      );

      // Observe all relevant elements
      const refs = [headingRef, descriptionRef]; // Added textContentRef2
      refs.forEach((ref) => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      });

      // Cleanup observer on component unmount
      return () => {
        refs.forEach((ref) => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        });
      };
    }, []);

    return (
      <div>
        <div className="background-c-services">
          <div className="contents" ref={headingRef}>
            <div className="titles">Advisory Services</div>
            <div className="descriptions" ref={descriptionRef}>
            Unlock your business potential and gain an edge in today’s competitive business environment.We are here to help you grow with our reliable and goal-driven advisory. 
              {/* Add your content here */}
            </div>
          </div>
        </div>
        <div class="bottom-design"></div>
  <div className="lease-container">
          <div className="lease-image">
            <img src={image1} className="image-advisory-services" />
            <div className="lease-description">
              <ul>
                <li>Shift your focus on growing your profit</li>
                <li>Save time by optimizing your processes</li>
                <li>Get a better understanding of complex finance data</li>
                <li>Boost your operations’ efficiency</li>
              </ul>
            </div>
          </div>
    </div>
    <div className="cta-container">
    <div className="cta-content">
      <h2>Invest in our experienced and certified professionals now.</h2>
      <p>Talk to our experts.</p>
      <button class='button-contact-us' onClick={handleButtonClick}>
        <svg
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M5 13c0-5.088 2.903-9.436 7-11.182C16.097 3.564 19 7.912 19 13c0 .823-.076 1.626-.22 2.403l1.94 1.832a.5.5 0 0 1 .095.603l-2.495 4.575a.5.5 0 0 1-.793.114l-2.234-2.234a1 1 0 0 0-.707-.293H9.414a1 1 0 0 0-.707.293l-2.234 2.234a.5.5 0 0 1-.793-.114l-2.495-4.575a.5.5 0 0 1 .095-.603l1.94-1.832C5.077 14.626 5 13.823 5 13zm1.476 6.696l.817-.817A3 3 0 0 1 9.414 18h5.172a3 3 0 0 1 2.121.879l.817.817.982-1.8-1.1-1.04a2 2 0 0 1-.593-1.82c.124-.664.187-1.345.187-2.036 0-3.87-1.995-7.3-5-8.96C8.995 5.7 7 9.13 7 13c0 .691.063 1.372.187 2.037a2 2 0 0 1-.593 1.82l-1.1 1.039.982 1.8zM12 13a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
            fill="currentColor"
          ></path>
        </svg>
        <span>Contact Us</span>
      </button>
    </div>
  </div>
      </div>
      
    );
};

export default Esg_Reporting;
