import React, { useRef, useEffect } from "react";
import image6 from './our-company-img.jpg';
import './About_Us_Our_Company.css';

const About_Us_Our_Company = () => {
  const headingRef = useRef(null);
  const descriptionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }
    if (descriptionRef.current) {
      observer.observe(descriptionRef.current);
    }

    return () => {
      if (headingRef.current) observer.unobserve(headingRef.current);
      if (descriptionRef.current) observer.unobserve(descriptionRef.current);
    };
  }, []);

  return (
    <div>
    <div className="background-about">
        <div className="contents" ref={headingRef}>
          <div className="titles">Our Company</div>
          <div className="descriptions" ref={descriptionRef}>
          Our purpose is to deliver quality end-to-end finance business services
          and solutions that cater to your needs. The business insights solutions
          and services we provide are designed to empower our customers to respond
          to ever-changing needs and challenges.<span className="shine4">JS Dynamics</span> has grown into
          one of the most trusted partners in shaping the future of finance.
          </div>
        </div>
      </div>
      
      </div>



  );
};

export default About_Us_Our_Company;