import React, { useRef, useEffect } from "react";
import './beps_pillar_two_global_tax_management.css';  // Import CSS styles
import { Link } from 'react-router-dom';
import image1 from './image1-piller.jpg';
import image2 from './image2-affected-company.jpg';
import image3 from './jns_pillar_challenges.png';
import image4 from './jns_pillar_challenges_mobile.png';
import image5 from './timeline_image.jpg';
import image6 from './image4.jpg';
import { FaDatabase, FaTools, FaCalculator, FaComments, FaHandshake } from "react-icons/fa";
const Esg_Reporting = () => {
    const headingRef = useRef(null);
    const descriptionRef = useRef(null);
    const textContentRef = useRef(null);
    const imageContentRef = useRef(null);
 // New ref for content-section-2
    const textContentsRef = useRef(null);  // Unique ref for content section 1
    const textContentRef2 = useRef(null); // Added ref for the missing .text-contents
    const textContentRef3 = useRef(null);
    const textContentRef4 = useRef(null);
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("in-view");
            } else {
              entry.target.classList.remove("in-view");
            }
          });
        },
        {
          threshold: 0.1, // Adjust the threshold to ensure animations trigger earlier
        }
      );

      // Observe all relevant elements
      const refs = [headingRef, descriptionRef, textContentRef, imageContentRef, textContentRef2, textContentsRef,textContentRef3,textContentRef4]; // Added textContentsRef
      refs.forEach((ref) => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      });

      // Cleanup observer on component unmount
      return () => {
        refs.forEach((ref) => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        });
      };
    }, []);


    const symbols = [
      {
        icon: <FaDatabase />,
        title: "Capture Data",
        description: "Calculate on an aggregated basis, considering all the entities within a multinational group",
      },
      {
        icon: <FaTools />,
        title: "Tool Overview",
        description: "Ensure precise 15% minimum tax calculations globally. Comprehensive risk management.",
      },
      {
        icon: <FaCalculator />,
        title: "Calculate",
        description: "Create a consolidated view of data to better position financial teams to make strategic decisions.",
      },
      {
        icon: <FaComments />,
        title: "Communicate",
        description: "Flexible reporting enables users to generate comprehensive reports and data schedules.",
      },
      {
        icon: <FaHandshake />,
        title: "Collaborate",
        description: "Facilitate real-time communication and collective decision-making for enhanced efficiency.",
      },
    ];

    return (
      <div>
        <div className="background-cS">
          <div className="contents" ref={headingRef}>
            <div className="titles"> BEPS Pillar Two Corporate Tax Management for OneStream</div>
            <div className="descriptions" ref={descriptionRef}>
            Navigate and automate evolving global regulations with Advanced Tax Connect for OneStream. A marketplace solution co-developed with ATC.
              {/* Add your content here */}
            </div>
          </div>
        </div>
        <div className="content-section">
        <div className="text-content text-contents" ref={textContentsRef}>
            <h2>What is Pillar Two?</h2>
            <p>
             Pillar Two is a tax system that will apply with uniform effect worldwide.
              Large-scale multinational corporations are required to pay a minimum of
              15% tax on their earnings in every country they operate in.
            </p>
           </div>
        </div>
        <div className="content-section">
          
          <div className="text-content" ref={textContentRef}>
            <h2>What is Advanced Tax Connect?</h2>
            <p>
            A complete Tax Performance management tool that guides you through data capture, calculation and reporting requirements including BEPS Pillar 2.
            The global tax landscape is rapidly evolving, and the BEPS initiative by the OECD is at the forefront of this transformation. Pillar 2, specifically, addresses the remaining challenges linked to the digitalization of the economy by ensuring multinational entities pay a minimum level of tax, regardless of where they're headquartered or the jurisdictions they operate in.
            This is an OneStream marketplace solution, enabling you to harness all the power the platform has to offer.</p>
          </div>
          <div className="image-content" ref={imageContentRef}>
            <img src={image1} alt="Lease Accounting" />
        
          </div>
          </div>
          
         <div className="company-affected">
          <h2>Which companies are effect by BEPS Pillar 2?</h2>
          <img src={image2}/>
         </div>

         <div className="challenge-container">
      <div className="challenge-content">
        <h2 className="challenge-title">Challenges</h2>
        <p className="challenge-description">
          Pillar Two presents new operational complexities for corporations to
          meet the presented requirements. High impact challenges.
        </p>
      </div>
      <div className="challenge-image">
        <img
          src={image3}
          alt="Challenges Grid"
          className="desktop-image"
        />
        <img
          src={image4}
          alt="Challenges Mobile Grid"
          className="mobile-image"
        />
      </div>
      
        </div>
        <div className="timeline-wrapper">
      <h1 className="timeline-header">The Pillar 2 Timeline</h1>
      <div className="timeline-image-wrapper">
        <img src={image5} alt="The Pillar 2 Timeline" className="timeline-img" />
      </div>
    </div>
    <div className="content-section">
          
          <div className="text-content" ref={textContentRef3}>
            <h2>How does Advanced Tax Connect work?</h2>
            <p  className="para-advanced-tax">
            Our solution serves as the linchpin for financial professionals, facilitating holistic data gathering essential for accurate tax calculations and compliance.</p>
            <br/>
            <p className="para-advanced-tax">
            By aggregating data across all entities within a multinational group, our tool ensures precision in minimum tax calculations under Pillar 2, guarding against inaccuracies, non-compliance, and potential penalties.</p>
            <br/>
            <p className="para-advanced-tax">
            With a focus on risk management, operational efficiency, and strategic decision-making, our software empowers financial teams to navigate BEPS-related challenges seamlessly, fostering proactive compliance, and informed, strategic financial decisions.</p>
          </div>
          </div>
          <div className="symbols-container"ref={textContentRef4}>
      {symbols.map((symbol, index) => (
        <div key={index} className="symbol-item">
          <div className="icon">{symbol.icon}</div>
          <h3>{symbol.title}</h3>
          <p>{symbol.description}</p>
        </div>
      ))}
    </div>
    <div className="lease-container">
          <div className="lease-image">
            <img src={image6} alt="Lease Accounting" />
            <div className="lease-description">
              <h2>Why OneStream Customers Choose Advanced Tax Connect</h2>
              <ul>
                <li>We don't just provide software; we offer a holistic approach to your Global Compliance and BEPS Pillar 2 journey.</li>
                <li>Our collaboration with the esteemed Tax Advisory ATC ensures that our solutions are not only technologically sound but also grounded in practical, real-world tax expertise.</li>
                <li>Benefit from the perfect blend of cutting-edge technology and seasoned tax expertise.</li>
                {/* <li>Instant overview of all leases</li>
                <li>Out of the box reports and disclosures to report under the new standards</li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Esg_Reporting;
