import React from 'react';
import './why_onestream.css'; // CSS file for styling
import onestream_logo from '../OneStream_Brandmark.png';
const OneStream = () => {
    const handleButtonClick = () => {
        window.open('https://www.onestream.com/', '_blank');
      };
  return (
    <div className="onestream-container">
    <div className="onestream-content">
      <div className="onestream-text">
        <h2>Why OneStream</h2>
        <p>
          After years of working with other EPM systems, OneStream Software really blew the <span className='para-in-black-words'> JS DYNAMICS</span> consultants away.
          The unified platform approach and the analysis possibilities were profound compared to the rest of the
          solutions in the market. The platform has continued to mature and add innovation since Day 1.
        </p>
        <p>
          Nowadays OneStream is acknowledged by research institute Nucleus as a market leader, scoring as one of
          the best on usability and functionality. Also OneStream received a 4.9 out of 5 star rating in Gartner
          Peer Insights, find out <a href="https://www.gartner.com/reviews/market/financial-close-and-consolidation-solutions" target='_blank'>why</a>.
        </p>
        <p className="discover-text para-in-black-words">
          Discover the endless possibilities OneStream has to offer.
        </p>
      </div>

      <div className="onestream-image-button">
        <img src={onestream_logo} alt="onestream logo" className="onestream-logo" />
        <button class="onestream-image-btn" onClick={handleButtonClick}>
            LEARN MORE ABOUT ONESTREAM ➔
        </button>

      </div>
    </div>
  </div>
  );
}

export default OneStream;
