import React, { useEffect, useRef } from 'react';
import './Cards_Down.css'; // Make sure to create this CSS file
import img1 from './images/image1.jpg';
import img2 from './images/image2.jpg';
import img3 from './images/image3.jpg';

// Card Component
const Card = ({ image, title, description, link }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("in-view");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (cardRef.current) {
      observer.observe(cardRef.current);
    }

    return () => {
      if (cardRef.current) {
        observer.unobserve(cardRef.current);
      }
    };
  }, []);

  return (
    <div ref={cardRef} className="card">
      <img src={image} alt={title} className="card-image" />
      <h4 className="card-title">{title}</h4>
      <p className="card-description">{description}</p>
      <a href={link} className="cta" target="_blank" rel="noopener noreferrer">
        <span>Read Blog</span>
        <svg width="13px" height="10px" viewBox="0 0 13 10">
          <path d="M1,5 L11,5"></path>
          <polyline points="8 1 12 5 8 9"></polyline>
        </svg>
      </a>
    </div>
  );
};

// Cards_Down Component
const Cards_Down = () => {
  const cardsData = [
    {
      image: img1,
      title: "How to Reference One Rule into Another Using Namespace Identifiers in OneStream",
      description: "When you’re dealing with various business rules in OneStream, things can get repetitive. Now, you might be thinking, “Can I just reuse some logic from one rule in another, instead of rewriting it from scratch?” The answer is a big...",
      link: "https://blog.jnsdynamics.com/how-to-reference-one-rule-into-another-using-namespace-identifiers-in-onestream/", // Unique link for the first card
    },
    {
      image: img2,
      title: "How to Import a DLL in OneStream: A Simple Guide",
      description: "So, you’re using OneStream, and now you’ve got a DLL (Dynamic Link Library) that you need to bring into the mix to add some custom functionality. Maybe it’s a specific library, a cool utility, or some external method you need...",
      link: "https://blog.jnsdynamics.com/how-to-import-a-dll-in-onestream-a-simple-guide/", // Unique link for the second card
    },
    {
      image: img3,
      title: "Business Rules in OneStream: Types and Use Case Guide",
      description: "When working with OneStream, understanding the various types of business rules is essential for customizing and automating your financial workflows. Let’s break down the 11 different business rules in OneStream and highlight their use cases. 1. Financial Rule This is...",
      link: "https://blog.jnsdynamics.com/business-rules-in-onestream-types-and-use-case-guide/", // Unique link for the third card
    },
  ];

  return (
    <div className="cards-container">
      {cardsData.map((card, index) => (
        <Card
          key={index}
          image={card.image}
          title={card.title}
          description={card.description}
          link={card.link} // Passing the unique link to each card
        />
      ))}
    </div>
  );
};

export default Cards_Down;
