import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css'; 
import logo from './logo.png'; 
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);
  const [isScrolled, setIsScrolled] = useState(false); // Track scroll state

  useEffect(() => {
    // Update active link based on the location
    let cleanPath = location.pathname.replace(/\/$/, '').toLowerCase();
    if (cleanPath === '') {
      cleanPath = '/';
    }
    setActiveLink(cleanPath);
  }, [location]);

  useEffect(() => {
    // Scroll event listener to track if the user scrolled down
    const handleScroll = () => {
      if (window.scrollY > 50) { // Adjust scroll value to fit your need
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header className={`header ${isScrolled ? 'scrolled' : 'initial'}`}>
      <a href='/'>
        <img src={logo} className='logo-navbar' alt='Logo' />
      </a>
      <input type='checkbox' id='check' />
      <label htmlFor='check' className='icons'>
        <FaBars id='menu-icon' />
        <FaTimes id='close-icon' />
      </label>
      <nav className='navbar'>
        <a href='/' className={activeLink === '/' ? 'active' : ''}>Home</a>
        <a href='/Solutions' className={activeLink === '/solutions' ? 'active' : ''}>Solutions</a>
        <a href='/Services' className={activeLink === '/services' ? 'active' : ''}>Services</a>
        <a href='/about-us' className={activeLink === '/about-us' ? 'active' : ''}>About</a>
        <a href='/contactus' className={activeLink === '/contactus' ? 'active' : ''}>Contact Us</a>
        <a href='https://blog.jnsdynamics.com/' className={activeLink === 'https://blog.jnsdynamics.com/' ? 'active' : ''}>Blogs</a>
      </nav>
    </header>
  );
};

export default Header;
