import React from 'react'
import Body from './Components/Header/Header'
import './Dashboard.css'; 

const Dashboard = () => {
  return (
    <div className='dashboard flex'>
        <div className="dashboardContainer flex">
             <Body/>
        </div>
    </div>
  )
}

export default Dashboard
